<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Lịch sử giao dịch ví</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Từ ngày">
              <b-form-input
                v-model="search.dateFrom"
                placeholder=""
                :type="`date`"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày">
              <b-form-input
                v-model="search.dateTo"
                placeholder=""
                :type="`date`"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Số tiền từ">
              <b-form-input
                v-model="search.amountFrom"
                placeholder=""
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Số tiền đến">
              <b-form-input
                v-model="search.amountTo"
                type="number"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="searchDetail()"
              >Tìm kiếm
            </b-button>
          </b-col>
        </b-row>
        <!--<b-row v-if="typeof infoMerchant !== null">-->
        <!--<b-col> <b>Account ID: </b> {{ this.infoMerchant.accountId }} </b-col>-->
        <!--<b-col>-->
        <!--<b>Merchant Code: </b> {{ this.infoMerchant.merchantCode }}-->
        <!--</b-col>-->
        <!--</b-row>-->
        <br />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(code)="data">
            <button @click="linkTransaction(data.item.code)">
              <span style="color: #3699ff">{{ data.item.code }}</span>
            </button>
          </template>
          <template #cell(amount)="data">
            <span
              v-if="
                (data.item.type == 1 ||
                  data.item.type == 6 ||
                  data.item.type == 17) &&
                data.item.maker_account == accountId
              "
            ></span>
            <span
              v-else-if="
                data.item.taker_account == accountId &&
                data.item.type !== 1 &&
                data.item.type !== 6 &&
                data.item.type !== 17
              "
            ></span>
            <span v-else>- </span>
            {{ data.item.amount }}
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status === 0" variant="warning"
              >Đang chờ
            </b-badge>
            <b-badge v-else-if="data.item.status === 1" variant="success"
              >Thành công
            </b-badge>
            <b-badge v-else-if="data.item.status === 2" variant="danger"
              >Đã hủy</b-badge
            >
            <b-badge v-else variant="light">Chưa hoàn thành</b-badge>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const AccountRepository = RepositoryFactory.get("account");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      fields: [
        { code: "Mã giao dịch" },
        { amount: "Số tiền" },
        { status: "Trạng thái" },
        { description: "Mô tả" },
        { created_at: "Ngày tạo" },
        { updated_at: "Ngày cập nhật" },
      ],
      search: {
        dateFrom: null,
        dateTo: null,
        amountFrom: null,
        amountTo: null,
      },
      url: this.$route,
      items: [],
      accountId: null,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lịch sử giao dịch ví", route: "enterpriseDetail" },
    ]);
  },
  methods: {
    searchDetail() {
      this.listAll();
    },
    listAll() {
      let numberPage = 1;
      if (typeof this.$route.query.page != "undefined") {
        numberPage = this.$route.query.page;
      }
      if (typeof this.$route.params.id != "undefined") {
        this.accountId = this.$route.params.id;
      }
      let params = {
        account_id: this.accountId,
        page: numberPage,
      };
      if (this.search.dateFrom != null && this.search.dateFrom != "") {
        params.date_from = this.search.dateFrom;
      }
      if (this.search.dateTo != null && this.search.dateTo != "") {
        params.date_to = this.search.dateTo;

        if (this.search.dateFrom > this.search.dateTo) {
          this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
          return;
        }
      }
      if (this.search.amountFrom != null && this.search.amountFrom != "") {
        params.amount_from = this.search.amountFrom;
      }
      if (this.search.amountTo != null && this.search.amountTo != "") {
        params.amount_to = this.search.amountTo;
        if (parseInt(this.search.amountTo) < parseInt(this.search.amountFrom)) {
          this.notifyAlert(
            "warn",
            "Số tiền bắt đầu phải nhỏ hơn số tiền kết thúc"
          );
          return;
        }
      }

      this.$bus.$emit("show-loading", true);
      AccountRepository.getListAccountEnterpriseDetail(params)
        .then((response) => {
          if (response.data.error_code) {
            Vue.notify({
              group: "foo",
              type: "warn",
              title: response.data.message,
            });
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    linkTransaction(transaction_id) {
      this.$router.push(
        "/transactions/detail?wallet_transaction_id=" + transaction_id
      );
    },
  },
  created() {
    this.listAll();
  },
  computed: {},
  watch: {
    currentPage() {
      this.listAll();
    },
  },
};
</script>
